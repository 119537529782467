import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, GridContainer } from "../components/common"
import media from "../styles/media"
import SEO from "../components/common/SEO"

const TermsContainer = styled(GridContainer)`
  padding-bottom: 100px;

  ${media.tablet`
    padding-top: 126px;
    margin-bottom: 86px;
  `}
`

const TermsTitle = styled.h1`
  font-weight: normal;
  font-family: StanleyRegular, serif;
  grid-column: 1 / span 3;
  grid-row: 1;
  margin: 0;

  ${media.tablet`
    grid-column: 1 / span 6;
  `}
`

const TermsContent = styled.div`
  display: grid;
  grid-column: 1 / -1;
  grid-row: 3;
  line-height: 30px;
  font-size: 20px;

  p {
    margin-top: 0;

    strong {
      display: block;
      margin-bottom: -1em;
    }
  }

  ${media.tablet`
    grid-column: 7 / span 6;
    grid-row: 1;
    margin-right: 20px;
  `}
`

const Terms = ({ data }) => {
  const termsPageContent = data.allContentfulTermsPage.edges[0].node
  const termsTitle = termsPageContent.termsTitle
  const copyBlock = termsPageContent.body.childMarkdownRemark.html

  return (
    <Layout headerTheme="dark">
      <SEO title="Columbia Artists - Terms of Use" path="/terms" />

      <TermsContainer>
        <TermsTitle dangerouslySetInnerHTML={{ __html: termsTitle }} />

        <TermsContent dangerouslySetInnerHTML={{ __html: copyBlock }} />
      </TermsContainer>
    </Layout>
  )
}

export default Terms

export const pageQuery = graphql`
  {
    allContentfulTermsPage {
      edges {
        node {
          termsPageMetaContent {
            metaDescription {
              metaDescription
            }
          }
          termsTitle
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
